<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-img v-if="avatar" :src="avatar" alt="User Avatar" />
        <v-icon large v-else> mdi-account-circle-outline </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img v-if="avatar" :src="avatar" alt="User Avatar" />
            <v-icon large v-else> mdi-account-circle-outline </v-icon>
          </v-list-item-avatar>

          <v-list-item-content v-if="!!sessionData.tenantUser">
            <v-list-item-title >{{
              sessionData.tenantUser.first_name + " " + sessionData.tenantUser.last_name
            }}</v-list-item-title>
          <v-list-item-subtitle>{{ sessionData.tenantUser.email }}</v-list-item-subtitle>
          <v-list-item-subtitle>
          </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title>
              {{ $t("auth.login.newUserTitle") }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ userData.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-container v-if="sessionData.tenantUser">
        <v-row class="d-flex justify-end pr-10 pb-4">
            <v-btn @click="signOut">{{$t('auth.logout.text')}}</v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      session: false,
      menu: false,
    };
  },
  computed: {
    ...mapGetters(["sessionData", "userData", "avatar"]),
  },
  methods: {
    ...mapActions(["signOut"])
  },
};
</script>

<style>
</style>
